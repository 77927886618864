var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('modalEmpleado',{attrs:{"modal":_vm.showEmpleado},on:{"cerrarModal":function($event){_vm.showEmpleado=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('modalRecurso',{attrs:{"modal":_vm.showModal},on:{"cerrarModal":function($event){_vm.showModal=false},"child-refresh":_vm.refrescarSelect}}),_c('CRow',{staticClass:"mt-3 mb-3"},[_c('CCol',{attrs:{"sm":"6"}},[_c('CRow',[_c('CCol',{staticClass:"px-0",staticStyle:{"margin-bottom":"20px"},attrs:{"sm":"11"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.role'),"horizontal":"","addLabelClasses":"text-right required","options":_vm.rolOptions,"is-valid":_vm.hasError(_vm.$v.StowageRoleId),"value":_vm.StowageRoleId,"invalid-feedback":_vm.$t('label.required')},on:{"update:value":function($event){_vm.StowageRoleId=$event}},model:{value:(_vm.$v.StowageRoleId.$model),callback:function ($$v) {_vm.$set(_vm.$v.StowageRoleId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.StowageRoleId.$model"}})],1),_c('CCol',{staticClass:"px-0",attrs:{"sm":"1"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.nuevo')+_vm.$t('label.role'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.nuevo')+$t('label.role'),\n                placement: 'top-end'\n              }"}],staticClass:"float-left",attrs:{"color":"add","size":"sm"},on:{"click":function($event){return _vm.habilitaCollapse(0)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}})],1)],1),_c('CCol',{staticClass:"px-0",staticStyle:{"margin-bottom":"20px"},attrs:{"sm":"11"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.ubication'),"horizontal":"","addLabelClasses":"text-right required","options":_vm.ubicationOptions,"is-valid":_vm.hasError(_vm.$v.StowageUbicationId),"value":_vm.StowageUbicationId,"invalid-feedback":_vm.$t('label.required')},on:{"update:value":function($event){_vm.StowageUbicationId=$event}},model:{value:(_vm.$v.StowageUbicationId.$model),callback:function ($$v) {_vm.$set(_vm.$v.StowageUbicationId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.StowageUbicationId.$model"}})],1),_c('CCol',{staticClass:"px-0",attrs:{"sm":"1"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.nueva')+_vm.$t('label.location'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.nueva')+$t('label.location'),\n                placement: 'top-end'\n              }"}],staticClass:"float-left",attrs:{"color":"add","size":"sm"},on:{"click":function($event){return _vm.habilitaCollapse(2)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}})],1)],1),(_vm.mostrarEmpresaBoolean)?_c('CCol',{staticClass:"px-0",staticStyle:{"margin-bottom":"10px"},attrs:{"sm":"11"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.company'),"horizontal":"","addLabelClasses":"text-right required","options":_vm.companyOptions,"value":_vm.ClientTpId},on:{"update:value":function($event){_vm.ClientTpId=$event}},model:{value:(_vm.ClientTpId),callback:function ($$v) {_vm.ClientTpId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"ClientTpId"}})],1):_vm._e(),(_vm.mostrarEmpresaBoolean)?_c('CCol',{staticClass:"px-0",attrs:{"sm":"1"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.nueva')+_vm.$t('label.company'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.nueva')+$t('label.company'),\n                placement: 'top-end'\n              }"}],staticClass:"float-left",attrs:{"color":"add","size":"sm"},on:{"click":function($event){return _vm.habilitaCollapse(1)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}})],1)],1):_vm._e()],1)],1),_c('CCol',{attrs:{"sm":"4"}},[_c('CRow',[_c('CCol',{staticStyle:{"margin-bottom":"20px"},attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.payrollType'),"horizontal":{ label: 'col-sm-5', input: 'col-sm-7'},"addLabelClasses":"text-right required","options":_vm.payrollOptions,"is-valid":_vm.hasError(_vm.$v.PayRollId),"value":_vm.PayRollId,"invalid-feedback":_vm.$t('label.required')},on:{"update:value":function($event){_vm.PayRollId=$event}},model:{value:(_vm.$v.PayRollId.$model),callback:function ($$v) {_vm.$set(_vm.$v.PayRollId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.PayRollId.$model"}})],1),_c('CCol',{staticStyle:{"margin-bottom":"20px"},attrs:{"sm":"12"}},[_c('CInput',{attrs:{"label":_vm.$t('label.quantity'),"addLabelClasses":"text-right required","horizontal":{ label: 'col-sm-5', input: 'col-sm-7'},"value":_vm.Quantity,"invalid-feedback":_vm.$t('label.required')+_vm.$t('label.onlyNumber'),"is-valid":_vm.hasError(_vm.$v.Quantity)},on:{"update:value":function($event){_vm.Quantity=$event}},model:{value:(_vm.$v.Quantity.$model),callback:function ($$v) {_vm.$set(_vm.$v.Quantity, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.Quantity.$model"}})],1)],1)],1),_c('CCol',{attrs:{"sm":"2"}},[(!_vm.editar)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content:  _vm.$t('label.add'),
              placement: 'top-end'
          }),expression:"{\n              content:  $t('label.add'),\n              placement: 'top-end'\n          }"}],staticClass:"mr-1",attrs:{"size":"sm","color":"add","disabled":_vm.desactivado},on:{"click":_vm.guardar}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1):_vm._e(),(_vm.editar)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content:  _vm.$t('label.add'),
              placement: 'top-end'
          }),expression:"{\n              content:  $t('label.add'),\n              placement: 'top-end'\n          }"}],staticClass:"mr-1",attrs:{"size":"sm","color":"add","disabled":_vm.desactivado},on:{"click":_vm.guardar}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content:  _vm.$t('label.clearFields'),
              placement: 'top-end'
          }),expression:"{\n              content:  $t('label.clearFields'),\n              placement: 'top-end'\n          }"}],staticClass:"mr-1",attrs:{"size":"sm","color":"wipe"},on:{"click":_vm.limpiarDatos}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.items,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Quantity",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(item.AssignedQuantity)+" / "+_vm._s(item.Quantity)+" ")])]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.employeesRole'),placement: 'top'}),expression:"{content: $t('label.employeesRole'),placement: 'top'}"}],attrs:{"color":"watch","size":"sm"},on:{"click":function($event){_vm.showEmpleado=item}}},[_c('CIcon',{attrs:{"name":"user"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.edit'),placement: 'top'}),expression:"{content: $t('label.edit'),placement: 'top'}"}],staticClass:"ml-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.editDatos(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.delete')}),expression:"{content: $t('label.delete')}"}],staticClass:"ml-1",attrs:{"color":"wipe","size":"sm"},on:{"click":function($event){return _vm.ConfirmarEliminado(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }